import { useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import { CheckIcon, Square2StackIcon } from "@heroicons/react/24/outline";
import { Shimmer } from "@sablier/v2-components/atoms";
import { Button, Checkbox } from "@sablier/v2-components/molecules";
import { StreamCategory, links } from "@sablier/v2-constants";
import { useChainData, useCopy } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Card as Base, Details } from "~/client/components/molecules";
import { pages } from "~/client/constants";
import {
  useCartStoreItem,
  useStreamCurrent,
  useStreamCurrentOnchain,
  useToken,
} from "~/client/hooks";
import Parties from "./Parties";
import Progress from "./Progress";

const WrapperPartial = styled(Base)`
  ${(props) => props.theme.styles.column}
  & {
    flex: 1;
    width: 100%;
    & > * {
      background-color: ${(props) => props.theme.colors.dark200};
    }
  }
`;

const Content = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.formEdge} * 3 / 4);
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding-top: calc(${(props) => props.theme.sizes.formEdge} * 3 / 4);
    & > * {
      width: 100%;
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${(props) => props.theme.colors.border};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
  width: 100%;
  column-gap: calc(${(props) => props.theme.sizes.edge} * 1);
`;

const Cell = styled.div<{ size?: number }>`
  grid-column: span ${(props) => props.size || 1};
`;

const Linker = styled.div`
  ${(props) => props.theme.styles.row}
`;

const LoadingDetail = styled(Details.Loading)`
  *[data-component="frame"] {
    background-color: ${(props) => props.theme.colors.dark300};
  }
`;

const special = css`
  br {
    display: none;
    visibility: hidden;
  }
  ${Grid} {
    grid-template-columns: 1fr;
    gap: calc(${(props) => props.theme.sizes.edge} * 1);
    ${Cell} {
      grid-column: span 1;
      & > * {
        flex-direction: row;
        gap: calc(${(props) => props.theme.sizes.edge} * 3 / 4);
        align-items: center;

        *[data-component="content"] {
          flex-direction: row;
          gap: 4px;
          justify-content: flex-start;
          align-items: center;
          label::after {
            content: ": ";
          }
        }
      }
    }
  }
`;

const Line = styled.div`
  width: 2px;
  height: 12px;
  background-color: ${(props) => props.theme.colors.gray500};
`;

const Extra = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 3 / 4);
  }
`;

const Wrapper = styled(WrapperPartial)`
  &[data-loading="true"] {
    height: 540px;
  }

  ${(props) => props.theme.medias.betweenLGAndXL} {
    ${special}
  }

  ${(props) => props.theme.medias.maxSM} {
    ${special}
  }

  ${(props) => props.theme.medias.maxXXS} {
    ${Grid} {
      ${Cell} {
        & > * {
          *[data-component="frame"] {
            display: none;
          }
        }
      }
    }
    ${Extra} {
      gap: 4px;
      *[data-component="button"] {
        gap: 0px;
      }
    }
  }
`;

function Share() {
  const { stream } = useStreamCurrent();
  const { t } = useT();

  const alias = useMemo(
    () => links.v2.client.concat(pages.profile.builder(stream?.alias)),
    [stream?.alias],
  );
  const [isAliasCopied, doAliasCopy] = useCopy(alias);

  return (
    <Linker onClick={doAliasCopy}>
      {isAliasCopied ? (
        <Button
          accent={"gray200"}
          appearance={"transparent"}
          isMini
          isUnpadded
          left={CheckIcon}
          title={t("structs.urlCopied")}
        />
      ) : (
        <Button
          accent={"gray400"}
          appearance={"transparent"}
          isMini
          isUnpadded
          left={Square2StackIcon}
          onClick={doAliasCopy}
          title={t("structs.shareURL")}
          titleShort={_.capitalize(t("words.share"))}
        />
      )}
    </Linker>
  );
}

function Select() {
  const { stream } = useStreamCurrent();
  const { add, remove, item } = useCartStoreItem(stream?.alias);

  const onAdd = useCallback(() => {
    if (stream) {
      add(stream);
    }
  }, [add, stream]);

  const onRemove = useCallback(() => {
    if (stream) {
      remove(stream.alias);
    }
  }, [remove, stream]);

  return <Checkbox onAdd={onAdd} onRemove={onRemove} value={!_.isNil(item)} />;
}

function Items() {
  const { isRecipient, isSender, stream, preview } = useStreamCurrent();

  const { chain } = useChainData(stream?.chainId);
  const token = useToken({ token: stream?.token });
  const { t } = useT();

  const parties = useMemo(() => {
    if (_.isNil(stream)) {
      return {
        from: {
          address: undefined,
          chainId: undefined,
          prefix: undefined,
        },
        to: {
          address: undefined,
          chainId: undefined,
          prefix: undefined,
        },
      };
    }

    return {
      from: {
        address: stream.proxied ? stream.proxender : stream.sender,
        chainId: stream?.chainId,
        prefix: isSender ? _.capitalize(t("words.you")) : undefined,
      },
      to: {
        address: stream.recipient,
        chainId: stream.chainId,
        prefix: isRecipient ? _.capitalize(t("words.you")) : undefined,
      },
    };
  }, [isRecipient, isSender, stream, t]);

  const cliff = useMemo(
    () => stream?.category === StreamCategory.LOCKUP_LINEAR && stream?.cliff,
    [stream],
  );

  return (
    <Wrapper
      title={t("words.attributes")}
      extra={
        <Extra>
          <Select />
          <Line />
          <Share />
        </Extra>
      }
    >
      <Content>
        <Parties {...parties} />
        <Divider />
        <Grid>
          <Cell>
            <Details.Shape value={preview.shape} />
          </Cell>
          <Cell>
            <Details.Status value={preview.status} />
          </Cell>
          <Cell>
            <Details.Amount
              label={_.upperFirst(t("structs.expectedPayout"))}
              value={{
                token,
                value: stream?.depositAmount.humanized.toString(),
              }}
            />
          </Cell>
          <Cell>
            <Details.Cancelable value={preview.cancelableHistorical} />
          </Cell>
          <Cell>
            <Details.Network value={chain} />
          </Cell>

          <Cell>
            <Details.StartedOnAdapting value={stream?.startTime} />
          </Cell>
          {cliff && (
            <Cell size={2}>
              <Details.Cliff
                isCliffing={preview.isCliffing}
                value={_.capitalize(preview.cliffDurationWorded)}
              />
            </Cell>
          )}
        </Grid>
        <Divider />
        <Progress />
      </Content>
    </Wrapper>
  );
}

function Loading() {
  return (
    <Wrapper data-loading={true} title={""}>
      <Content>
        <Parties />
        <Divider />
        <Grid>
          <Cell>
            <LoadingDetail />
          </Cell>
          <br />
          <Cell>
            <LoadingDetail />
          </Cell>
          <Cell>
            <LoadingDetail />
          </Cell>
          <Cell>
            <LoadingDetail />
          </Cell>
          <Cell>
            <LoadingDetail />
          </Cell>
        </Grid>
        <Divider />
        <Shimmer
          purpose={"value"}
          height={78}
          background={"dark300"}
          foreground={"dark300"}
        />
      </Content>
    </Wrapper>
  );
}

function Slow() {
  const { chainId, data } = useStreamCurrentOnchain();

  const parties = useMemo(() => {
    return {
      from: {
        address: data?.sender,
        chainId,
      },
      to: {
        address: data?.recipient,
        chainId,
      },
    };
  }, [chainId, data]);

  return (
    <Wrapper data-loading={true} title={""}>
      <Content>
        <Parties {...parties} />
        <Divider />
        <Grid>
          <Cell>
            <LoadingDetail />
          </Cell>
          <br />
          <Cell>
            <LoadingDetail />
          </Cell>
          <Cell>
            <LoadingDetail />
          </Cell>
          <Cell>
            <LoadingDetail />
          </Cell>
          <Cell>
            <LoadingDetail />
          </Cell>
        </Grid>
        <Divider />
        <Shimmer
          purpose={"value"}
          height={78}
          background={"dark300"}
          foreground={"dark300"}
        />
      </Content>
    </Wrapper>
  );
}

export { Loading, Slow };
export default Items;
