import { useCallback } from "react";
import { _ } from "@sablier/v2-mixins";
import { useRouter } from "next/router";

export default function useBack(fallback: string) {
  const router = useRouter();

  const goBack = useCallback(() => {
    if (router.isReady && _.isWindow()) {
      if ((window.history?.length || 0) <= 1) {
        void router.push(fallback, undefined, { shallow: true, scroll: false });
      } else {
        void router.back();
      }
    } else {
      void router.back();
    }
  }, [router, fallback]);

  return {
    goBack,
  };
}
