import { useMemo } from "react";
import styled from "styled-components";
import {
  Label,
  ProgressBox as ProgressBoxPartial,
  Tooltip,
} from "@sablier/v2-components/molecules";
import { useT } from "@sablier/v2-locales";
import { useStreamCurrent } from "~/client/hooks";
import type { ComponentProps } from "react";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    gap: calc(${(props) => props.theme.sizes.formEdge});
    width: 100%;
  }
`;

const Cell = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    position: relative;
    flex: 1;
    row-gap: calc(${(props) => props.theme.sizes.edge});
  }
`;

const ProgressBox = styled(ProgressBoxPartial)`
  width: 100%;
  & > div {
    height: 46px;
    border: none;
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxXL} {
    gap: 4px;
    ${Cell} {
      flex: auto;
      width: 100%;
    }
  }
  ${(props) => props.theme.medias.maxXS} {
    ${(props) => props.theme.styles.column}
    & {
      gap: calc(${(props) => props.theme.sizes.edge});
      width: 100%;
    }
  }
`;

type Props = ComponentProps<typeof ProgressBoxPartial>;

function Progress() {
  const { t } = useT();
  const { stream, preview } = useStreamCurrent();

  const streamed: Props = useMemo(
    () => ({
      color: stream?.isCanceled ? "red" : "secondaryDesaturated",
      percentage: stream?.streamedAmountPercentage.toNumber(),
      purpose: preview.progressBox,
    }),
    [preview, stream],
  );

  const withdrawn: Props = useMemo(
    () => ({
      color: "primaryMiddle",
      percentage: stream?.withdrawnAmountPercentage.toNumber(),
      purpose: preview.progressBox,
    }),
    [preview, stream],
  );

  return (
    <Wrapper>
      <Tooltip value={t("descriptions.streamedAmount")} maxWidth={"200px"}>
        <Cell>
          <Label value={t("structs.streamedAmount")} />
          <ProgressBox {...streamed} />
        </Cell>
      </Tooltip>
      <Tooltip value={t("descriptions.withdrawnAmount")} maxWidth={"200px"}>
        <Cell>
          <Label value={t("structs.withdrawnAmount")} />
          <ProgressBox {...withdrawn} />
        </Cell>
      </Tooltip>
    </Wrapper>
  );
}

export default Progress;
