import styled from "styled-components";
import { Emoji, External } from "@sablier/v2-components/atoms";
import { Amount } from "@sablier/v2-components/molecules";
import { tags } from "@sablier/v2-constants";
import { useChainExplorer, useShortAddress } from "@sablier/v2-hooks";
import { Trans, useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { useStreamCurrentOnchain, useToken } from "~/client/hooks";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    position: relative;
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1);
    justify-content: center;
    align-items: flex-start;
    padding-top: 300px;
    & > * {
      z-index: ${(props) => props.theme.sizes.zIndexOver};
    }
  }
`;

const Heading = styled.div`
  & > h1 {
    ${(props) => props.theme.styles.textSection}
    & {
      font-size: 64pt;
    }
  }
  & > h2 {
    ${(props) => props.theme.styles.textSection}
  }
`;

const Description = styled.div`
  ${(props) => props.theme.styles.textParagraphMaxi}
  & {
    display: inline-block;
    flex-wrap: wrap;
    max-width: 520px;
    line-height: 1.8;
    vertical-align: middle;

    a {
      color: ${(props) => props.theme.colors.yellow};
      &:hover,
      &:active {
        text-decoration: underline;
      }
    }

    & > div > div {
      & > *:nth-child(1) {
        margin-inline: 3px;
        img {
          margin-top: 2px;
        }
      }
    }

    * {
      display: inline-block;
      p,
      span {
        ${(props) => props.theme.styles.textParagraphMaxi}
      }
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxXL} {
    width: 100%;
    max-width: ${(props) => props.theme.sizes.profileMini};
    margin-inline: auto;
    padding-top: calc(${(props) => props.theme.sizes.edge} * 4);
    padding-bottom: calc(${(props) => props.theme.sizes.edge} * 2);
  }

  ${(props) => props.theme.medias.maxSM} {
    padding-bottom: calc(${(props) => props.theme.sizes.edge} * 2);
  }
`;

export default function Slow() {
  const { t } = useT();
  const { address, chainId, data, tokenId } = useStreamCurrentOnchain();

  const sender = useShortAddress(data?.sender);
  const recipient = useShortAddress(data?.recipient);
  const token = useToken({ address: data?.asset, chainId });

  const explorer = useChainExplorer({
    chainId,
    type: "nft",
    hash: address,
    tokenId,
  });

  if (_.isNilOrEmptyString(data)) {
    return false;
  }

  return (
    <Wrapper data-cy={tags.streamFallback}>
      <Heading>
        <h1>
          <Emoji symbol={"⏳🙌"} />
        </h1>
      </Heading>
      <Heading>
        <h2>{t("structs.slowToIndex")}</h2>
      </Heading>
      <Description>
        <Trans
          i18nKey={"errors.slowStream"}
          values={{ sender, recipient }}
          components={[
            <External key={0} to={explorer} />,
            <Amount key={1} token={token} isValueHidden size={[18, 14]} />,
          ]}
        />
      </Description>
    </Wrapper>
  );
}
