import styled from "styled-components";
import { InboxArrowDownIcon } from "@heroicons/react/24/outline";
import { Emoji } from "@sablier/v2-components/atoms";
import { Icon } from "@sablier/v2-components/molecules";
import { tags } from "@sablier/v2-constants";
import { Trans, useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1);
    justify-content: center;
    align-items: flex-start;
    padding-top: 300px;
  }
`;

const Heading = styled.div`
  & > h1 {
    ${(props) => props.theme.styles.textSection}
    & {
      font-size: 64pt;
    }
  }
  & > h2 {
    ${(props) => props.theme.styles.textSection}
  }
`;

const Description = styled.div`
  ${(props) => props.theme.styles.textParagraphMaxi}
  & {
    max-width: 520px;
    line-height: 1.8;
    vertical-align: middle;
    & > * {
      display: inline-block !important;
      vertical-align: middle;
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxXL} {
    width: 100%;
    max-width: ${(props) => props.theme.sizes.profileMini};
    margin-inline: auto;
    padding-top: calc(${(props) => props.theme.sizes.edge} * 4);
    padding-bottom: calc(${(props) => props.theme.sizes.edge} * 2);
  }

  ${(props) => props.theme.medias.maxSM} {
    padding-bottom: calc(${(props) => props.theme.sizes.edge} * 2);
  }
`;

export default function Missing() {
  const { t } = useT();

  return (
    <Wrapper data-cy={tags.streamFallback}>
      <Heading>
        <h1>
          <Emoji symbol={"🫤🫡"} />
        </h1>
      </Heading>
      <Heading>
        <h2>{t("structs.somethingWrong")}</h2>
      </Heading>
      <Description>
        <Trans
          i18nKey={"errors.missingStream"}
          components={[<Icon key={"0"} value={InboxArrowDownIcon} />]}
        />
      </Description>
    </Wrapper>
  );
}
