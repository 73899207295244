import { useEffect, useMemo } from "react";
import styled from "styled-components";
import { Header } from "@sablier/v2-components/molecules";
import { routes } from "@sablier/v2-constants";
import { useBack } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { useRouter } from "next/router";
import { useStreamCurrent, useStreamCurrentOnchain } from "~/client/hooks";
import Actions from "./Actions";
import Advanced from "./Advanced";
import Details from "./Details";
import Loading from "./Loading";
import Missing from "./Missing";
import Slow from "./Slow";
import Timeline from "./Timeline";

const WrapperPartial = styled.aside`
  ${(props) => props.theme.styles.column}
  & {
    align-items: flex-start;
    width: var(--panel);
    padding-left: calc(${(props) => props.theme.sizes.edge} * 2);

    header[data-component="header"] {
      width: 500px;
    }
  }
`;

const Cards = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1);
    align-items: flex-start;
    width: 100%;
    padding-bottom: calc(${(props) => props.theme.sizes.edge} * 2);
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxContainer} {
    padding-left: calc(${(props) => props.theme.sizes.edge} * 1);
    ${Cards} {
      padding-bottom: calc(${(props) => props.theme.sizes.edge} * 1);
    }
  }

  ${(props) => props.theme.medias.maxLG} {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1);
    align-items: center;
    width: 100%;
    margin-top: calc(${(props) => props.theme.sizes.edge} * 0);
    padding-left: calc(${(props) => props.theme.sizes.edge} * 0);
    & > * {
      max-width: ${(props) => props.theme.sizes.profileMini};
      margin: 0 auto;
    }
    & > *:first-child {
      padding: ${(props) => props.theme.sizes.edge};
      border-radius: 12px;
      background: linear-gradient(
        180deg,
        transparent,
        ${(props) => props.theme.colors.dark200}
      );
    }
  }
  ${(props) => props.theme.medias.maxSM} {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);

    header[data-component="header"] {
      width: 100%;
    }
    ${Cards} {
      row-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    }
  }
`;

const path = [
  {
    title: routes.client.pages.dashboard.title,
    toPage: routes.client.pages.dashboard.builder(),
  },
  { title: routes.client.pages.profile.title },
];

function Panel() {
  const {
    isLoading,
    stream,
    preview,
    isMissing: isMissingOffchain,
  } = useStreamCurrent();
  const { isMissing: isMissingOnchain } = useStreamCurrentOnchain();

  const { t } = useT();
  const router = useRouter();
  const { goBack } = useBack(routes.client.pages.dashboard.builder());

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, [router]);

  const title = useMemo(() => {
    if (isLoading || _.isNil(stream) || _.isNilOrEmptyString(preview.title)) {
      return _.capitalize(t("words.stream"));
    }

    return `${_.capitalize(t("words.stream"))} ${preview.title}`;
  }, [isLoading, stream, preview, t]);

  if (isLoading) {
    return <Loading />;
  }

  if (!isMissingOnchain && isMissingOffchain) {
    return <Slow />;
  }

  if (isMissingOffchain && isMissingOnchain) {
    return <Missing />;
  }

  return (
    <Wrapper>
      <Header back={goBack} path={path} title={title} />
      <Cards>
        <Details />
        <Timeline />
        <Advanced />
        <Actions />
      </Cards>
    </Wrapper>
  );
}

export default Panel;
