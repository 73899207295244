import { useCallback, useMemo } from "react";
import styled from "styled-components";
import {
  ArrowDownTrayIcon,
  ArrowUpRightIcon,
  EllipsisHorizontalIcon,
  HandRaisedIcon,
  MapIcon,
  PaperAirplaneIcon,
  RectangleStackIcon,
  StopCircleIcon,
} from "@heroicons/react/24/outline";
import { Button } from "@sablier/v2-components/molecules";
import { links } from "@sablier/v2-constants";
import { useChainData } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import { Card as Base } from "~/client/components/molecules";
import {
  useCoverStreamCancel,
  useCoverStreamRenounce,
  useCoverStreamTransfer,
  useCoverStreamWithdraw,
  useModalStreamDetails,
  useModalStreamHistory,
  useModalStreamOptions,
  useStreamCurrent,
} from "~/client/hooks";

const WrapperPartial = styled(Base)`
  ${(props) => props.theme.styles.column}
  & {
    flex: 1;
    width: 100%;
    & > * {
      background-color: ${(props) => props.theme.colors.dark200};
    }
  }
`;

const Content = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.formEdge} * 3 / 4);
    width: 100%;
    padding-top: calc(${(props) => props.theme.sizes.formEdge} * 3 / 4);

    &[data-loading="true"] {
      height: 60px;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: calc(${(props) => props.theme.sizes.edge} * 1);
  width: 100%;
`;

const Cell = styled.div`
  grid-column: span 1;
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxXL} {
    ${Grid} {
      gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    }
  }
  ${(props) => props.theme.medias.maxXXS} {
    ${Grid} {
      grid-template-columns: 1fr;
    }
  }
`;

function organize(isSender: boolean) {
  const list = [
    "details",
    "history",
    "transfer",
    "cancel",
    "withdraw",
    "opensea",
  ];

  if (isSender) {
    list.push("disable");
  }

  list.push("more");

  return list;
}

function Actions() {
  const { isSender, stream } = useStreamCurrent();
  const { doOpen: doOpenDetails } = useModalStreamDetails();
  const { doOpen: doOpenOptions } = useModalStreamOptions();
  const { doOpen: doOpenRenounce } = useCoverStreamRenounce();
  const { doOpen: doOpenWithdraw } = useCoverStreamWithdraw();
  const { doOpen: doOpenTransfer } = useCoverStreamTransfer();
  const { doOpen: doOpenHistory } = useModalStreamHistory();
  const { doOpen: doOpenCancel } = useCoverStreamCancel();
  const { t } = useT();

  const actions = useMemo(() => organize(isSender), [isSender]);
  const { chain } = useChainData(stream?.chainId);

  const openDetails = useCallback(() => {
    vendors.track.log((events) => {
      return events.openStreamDetails("actionsPanel");
    });
    doOpenDetails();
  }, [doOpenDetails]);

  const openHistory = useCallback(() => {
    vendors.track.log((events) => {
      return events.openModalFrom({
        nameKey: "history",
        placeKey: "actionsPanel",
      });
    });
    doOpenHistory();
  }, [doOpenHistory]);

  const openOptions = useCallback(() => {
    vendors.track.log((events) => {
      return events.openModalFrom({
        nameKey: "options",
        placeKey: "actionsPanel",
      });
    });
    doOpenOptions();
  }, [doOpenOptions]);

  const openRenounce = useCallback(() => {
    vendors.track.log((events) => {
      return events.openCover({
        nameKey: "renounce",
        placeKey: "actionsPanel",
      });
    });
    doOpenRenounce();
  }, [doOpenRenounce]);

  const openTransfer = useCallback(() => {
    vendors.track.log((events) => {
      return events.openCover({
        nameKey: "transfer",
        placeKey: "actionsPanel",
      });
    });
    doOpenTransfer();
  }, [doOpenTransfer]);

  const openWithdraw = useCallback(() => {
    vendors.track.log((events) => {
      return events.openCover({
        nameKey: "withdraw",
        placeKey: "actionsPanel",
      });
    });
    doOpenWithdraw();
  }, [doOpenWithdraw]);

  const openCancel = useCallback(() => {
    vendors.track.log((events) => {
      return events.openCover({
        nameKey: "cancel",
        placeKey: "actionsPanel",
      });
    });
    doOpenCancel();
  }, [doOpenCancel]);

  const toOpensea = useMemo(() => {
    if (_.isNilOrEmptyString(stream)) {
      return "";
    }

    return links.nft.opensea(
      chain?.name.toLowerCase(),
      stream?.contract,
      stream?.tokenId,
    );
  }, [chain, stream]);

  return (
    <Wrapper title={t("words.actions")}>
      <Content>
        <Grid>
          {actions.map((action) => (
            <Cell key={action}>
              {action === "history" && (
                <Button
                  accent={"iconic"}
                  appearance={"outline"}
                  right={RectangleStackIcon}
                  title={t("words.history")}
                  onClick={openHistory}
                />
              )}

              {action === "withdraw" && (
                <Button
                  accent={"iconic"}
                  appearance={"outline"}
                  onClick={openWithdraw}
                  right={ArrowDownTrayIcon}
                  title={_.capitalize(t("words.withdraw"))}
                />
              )}

              {action === "cancel" && (
                <Button
                  accent={"iconic"}
                  appearance={"outline"}
                  onClick={openCancel}
                  right={StopCircleIcon}
                  title={_.capitalize(t("words.cancel"))}
                />
              )}

              {action === "transfer" && (
                <Button
                  accent={"iconic"}
                  appearance={"outline"}
                  onClick={openTransfer}
                  right={PaperAirplaneIcon}
                  title={t("words.transfer")}
                />
              )}
              {action === "disable" && (
                <Button
                  accent={"iconic"}
                  appearance={"outline"}
                  onClick={openRenounce}
                  right={HandRaisedIcon}
                  title={t("words.cancelability")}
                />
              )}

              {action === "details" && (
                <Button
                  accent={"iconic"}
                  appearance={"outline"}
                  right={MapIcon}
                  title={t("words.details")}
                  onClick={openDetails}
                />
              )}

              {action === "more" && (
                <Button
                  accent={"iconic"}
                  appearance={"outline"}
                  onClick={openOptions}
                  right={EllipsisHorizontalIcon}
                  title={t("structs.moreOptions")}
                  titleShort={t("words.more")}
                />
              )}

              {action === "opensea" ? (
                <Button
                  accent={"iconic"}
                  appearance={"outline"}
                  title={"OpenSea"}
                  right={ArrowUpRightIcon}
                  purpose={"external"}
                  to={toOpensea}
                />
              ) : (
                false
              )}
            </Cell>
          ))}
        </Grid>
      </Content>
    </Wrapper>
  );
}

function Loading() {
  return (
    <Wrapper isTopHidden={"preserve"}>
      <Content data-loading={true} />
    </Wrapper>
  );
}
export { Loading };

export default Actions;
