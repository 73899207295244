import { useMemo } from "react";
import styled from "styled-components";
import { Meta } from "@sablier/v2-components/organisms";
import { _ } from "@sablier/v2-mixins";
import { useRouter } from "next/router";
import { Page as PagePartial } from "~/client/components/organisms";
import useIsSanctionedStream from "~/client/hooks/useIsSanctionedStream";
import usePolling from "~/client/hooks/usePolling";
import useStreamCurrent from "~/client/hooks/useStreamCurrent";
import useStreamMeta from "~/client/hooks/useStreamMeta";
import Body from "./Body";

const Container = styled.div`
  ${(props) => props.theme.styles.contained}
  & {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: flex-start;
    width: 100%;
  }
`;

const Left = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    flex: 1;
    height: 100%;
    min-height: calc(100vh - ${(props) => props.theme.sizes.toolbar});
    border-right: 2px solid ${(props) => props.theme.colors.border};
  }
`;

const Right = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    align-items: flex-start;
    height: 100%;
  }
`;

const Page = styled(PagePartial)`
  ${(props) => props.theme.medias.maxLG} {
    ${Container} {
      grid-template-columns: 1fr;
      width: 100%;
      max-width: 100vw;
      & > * {
        column-span: span 1;
      }
    }
    ${Left} {
      width: 100%;
      min-height: auto;
      border: none;
    }
    ${Right} {
      width: 100%;
      height: auto;
    }
  }
`;

/**
 * This page supports server-provided data (through dehydration).
 * It has been disabled at the moment, so the page will work with CSR data but
 * by adding a getStaticProps method (+ pass down the dehydrated props) one could use
 * the eager functionality.
 */

function Profile() {
  usePolling({ owned: false, preview: true, page: "profile" });

  const router = useRouter();
  const id = useMemo(
    () => router.query?.id?.toString() || undefined,
    [router.query],
  );

  const { stream } = useStreamCurrent();
  const { canonical, title, image } = useStreamMeta(stream, id);
  const { isSanctioned } = useIsSanctionedStream();

  if (isSanctioned) {
    return false;
  }

  return (
    <Page>
      <Meta.Head canonical={canonical} title={title} image={image} />
      <Body />
    </Page>
  );
}

export default Profile;
