import { Conditional } from "@sablier/v2-components/atoms";
import { _ } from "@sablier/v2-mixins";
import { useStreamCurrent, useStreamCurrentOnchain } from "~/client/hooks";
import Active from "./Active";
import Canceled from "./Canceled";
import Loading from "./Loading";
import Missing from "./Missing";
import Slow from "./Slow";

function Display() {
  const { isReady, isMissing: isMissingOffchain, stream } = useStreamCurrent();
  const { isMissing: isMissingOnchain } = useStreamCurrentOnchain();

  return (
    <>
      <Conditional on={!isReady}>
        <Loading />
      </Conditional>
      <Conditional on={isReady && !isMissingOffchain}>
        {stream?.isCanceled ? <Canceled /> : <Active />}
      </Conditional>
      <Conditional
        on={isReady && !isMissingOnchain && isMissingOffchain}
        delay={[500, 0]}
      >
        <Slow />
      </Conditional>

      <Conditional
        on={isReady && isMissingOffchain && isMissingOnchain}
        delay={[500, 0]}
      >
        <Missing />
      </Conditional>
    </>
  );
}

export default Display;
