import styled from "styled-components";
import { Header } from "@sablier/v2-components/molecules";
import { routes } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import * as Details from "../Details";
import * as Timeline from "../Timeline";

const WrapperPartial = styled.aside`
  ${(props) => props.theme.styles.column}
  & {
    align-items: flex-start;
    width: var(--panel);
    padding-left: calc(${(props) => props.theme.sizes.edge} * 2);
    header[data-component="header"] {
      width: 500px;
    }
  }
`;

const Cards = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1);
    align-items: flex-start;
    width: 100%;
    padding-bottom: calc(${(props) => props.theme.sizes.edge} * 2);
    & > * {
      width: 100%;
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxContainer} {
    padding-left: calc(${(props) => props.theme.sizes.edge} * 1);
    ${Cards} {
      padding-bottom: calc(${(props) => props.theme.sizes.edge} * 1);
    }
  }

  ${(props) => props.theme.medias.maxLG} {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1);
    align-items: center;
    width: 100%;
    margin-top: calc(${(props) => props.theme.sizes.edge} * 0);
    padding-left: calc(${(props) => props.theme.sizes.edge} * 0);
    & > * {
      max-width: ${(props) => props.theme.sizes.profileMini};
      margin: 0 auto;
    }
    & > *:first-child {
      padding: ${(props) => props.theme.sizes.edge};
      border-radius: 12px;
      background: linear-gradient(
        180deg,
        transparent,
        ${(props) => props.theme.colors.dark200}
      );
    }
  }
  ${(props) => props.theme.medias.maxSM} {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);

    header[data-component="header"] {
      width: 100%;
    }
    ${Cards} {
      row-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    }
  }
`;

const path = [
  {
    title: routes.client.pages.dashboard.title,
    toPage: routes.client.pages.dashboard.builder(),
  },
  { title: routes.client.pages.profile.title },
];

function Missing() {
  const { t } = useT();

  return (
    <Wrapper>
      <Header
        back={path[0].toPage}
        path={path}
        title={t("structs.streamNotFound")}
      />
      <Cards>
        <Details.Loading />
        <Timeline.Loading />
      </Cards>
    </Wrapper>
  );
}

export default Missing;
