import { useCallback } from "react";
import styled from "styled-components";
import { StreamCategory } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import { Card as Base, Details } from "~/client/components/molecules";
import { useModalStreamSimulator, useStreamCurrent } from "~/client/hooks";

const WrapperPartial = styled(Base)`
  ${(props) => props.theme.styles.column}
  & {
    flex: 1;
    width: 100%;
    & > * {
      background-color: ${(props) => props.theme.colors.dark200};
      will-change: background-color;
      transition: background-color 150ms;
    }
  }
`;

const Content = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    row-gap: calc(${(props) => props.theme.sizes.formEdge} * 1);
    width: 100%;
    &[data-loading="true"] {
      height: 60px;
    }
    & > *:first-child {
      margin-right: auto;
    }
    * {
      cursor: pointer !important;
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxXS} {
    ${Content} {
      [data-component="value"] p {
        line-height: 20pt;
      }
    }
  }

  &:hover,
  &:active {
    & > * {
      background-color: ${(props) => props.theme.colors.dark250};
      will-change: background-color;
      transition: background-color 150ms;
    }
  }
`;

function Advanced() {
  const { t } = useT();
  const { isReady, stream } = useStreamCurrent();
  const { setOpen: setOpenSimulator } = useModalStreamSimulator();

  const onClick = useCallback(() => {
    if (!_.isNil(stream)) {
      vendors.track.log((events) => {
        return events.openModalFrom({
          nameKey: "simulator",
          placeKey: "curve",
        });
      });
      setOpenSimulator(true, { stream });
    }
  }, [setOpenSimulator, stream]);

  if (
    !isReady ||
    (stream?.category !== StreamCategory.LOCKUP_DYNAMIC &&
      stream?.category !== StreamCategory.LOCKUP_TRANCHED)
  ) {
    return false;
  }

  return (
    <Wrapper isTopHidden>
      <Content onClick={onClick}>
        <Details.Advanced
          category={stream?.category}
          value={t("structs.usesCustomCurve")}
        />
      </Content>
    </Wrapper>
  );
}

export default Advanced;
