import styled from "styled-components";
import { Button } from "@sablier/v2-components/molecules";
import { StreamCircle } from "@sablier/v2-components/organisms";
import { _ } from "@sablier/v2-mixins";
import { useStreamCircleSize } from "~/client/hooks";

const WrapperPartial = styled.aside`
  ${(props) => props.theme.styles.column}
  & {
    align-items: center;
    width: 100%;
    min-height: 466px;
    padding-right: calc(${(props) => props.theme.sizes.edge} * 2);
  }
`;

const Content = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: 32px;
    align-items: center;
    width: 100%;
    padding-top: 84px;
    opacity: 1;
    transition: opacity 1s ease-out;

    &[data-loading="true"] {
      opacity: 500ms;
      transition: opacity 1s ease-out;
    }
  }
`;

const Main = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    position: relative;
    align-items: center;
    width: 100%;
  }
`;

const Legend = styled(Button)`
  width: 130px;
  height: 36px;
  margin-bottom: 12px;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
  width: 100%;
  max-width: ${(props) => _.toSuffix(props.theme.sizes.streamCircle, "px")};
  & > * {
    grid-column: span 1;
    height: 58px;
  }
  &:not([data-placeholder="true"]) {
    ${(props) => props.theme.animations.fadeIn}
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxXL} {
    padding-right: calc(${(props) => props.theme.sizes.edge} * 1);
    ${Content} {
      padding-top: 32px;
      & > *:nth-child(1) {
        margin-bottom: 32px;
      }
      & > *:nth-child(2) {
        margin-bottom: 32px;
      }
    }
  }

  ${(props) => props.theme.medias.maxLG} {
    padding-right: 0;
  }

  ${(props) => props.theme.medias.maxSM} {
    ${Content} {
      row-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      padding-top: calc(${(props) => props.theme.sizes.edge} * 1 / 2);

      & > *:nth-child(1) {
        margin-bottom: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      }
      & > *:nth-child(2) {
        margin-bottom: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      }
    }
    ${Row} {
      gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      padding-bottom: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    }
    ${Main} {
      padding-top: calc(${(props) => props.theme.sizes.edge} * 1);
      padding-bottom: calc(${(props) => props.theme.sizes.edge} * 0);
    }
  }

  ${(props) => props.theme.medias.maxXS} {
    ${Row} {
      grid-template-columns: 1fr 1fr;
    }
  }
`;

function Top() {
  const { sizes } = useStreamCircleSize();

  return (
    <Main>
      <StreamCircle isLoading size={sizes.width} />
    </Main>
  );
}

function Loading() {
  return (
    <Wrapper>
      <Content>
        <Top />
        <Legend
          accent={"dark200"}
          appearance={"solid"}
          isPreview
          isMini
          title={""}
        />
        <Row data-placeholder={true}>
          <Button
            accent={"dark200"}
            appearance={"solid"}
            isPreview
            title={""}
          />
          <Button
            accent={"dark200"}
            appearance={"solid"}
            isPreview
            title={""}
          />
        </Row>
      </Content>
    </Wrapper>
  );
}

export default Loading;
