import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { ArrowsRightLeftIcon } from "@heroicons/react/24/outline";
import { ArrowTrendingUpIcon } from "@heroicons/react/24/outline";
import { Button } from "@sablier/v2-components/molecules";
import { useT } from "@sablier/v2-locales";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import { Card as Base, Details } from "~/client/components/molecules";
import { useModalStreamSimulator, useStreamCurrent } from "~/client/hooks";

const WrapperPartial = styled(Base)`
  ${(props) => props.theme.styles.column}
  & {
    flex: 1;
    width: 100%;
    & > * {
      background-color: ${(props) => props.theme.colors.dark200};
    }
  }
`;

const Content = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    row-gap: calc(${(props) => props.theme.sizes.formEdge} * 1);
    width: 100%;
    &[data-loading="true"] {
      height: 60px;
    }
    & > *:first-child {
      margin-right: auto;
    }
  }
`;

const Actions = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    flex-shrink: 0;
    gap: ${(props) => props.theme.sizes.edge};
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.betweenLGAndXL} {
    ${Actions} {
      & > *:nth-child(2) {
        display: none;
      }
    }
  }

  ${(props) => props.theme.medias.maxSM} {
    ${Actions} {
      display: none;
    }
  }
`;

function Timeline() {
  const [isDuration, setIsDuration] = useState<boolean>(false);
  const { stream, preview } = useStreamCurrent();
  const { setOpen: setOpenSimulator } = useModalStreamSimulator();
  const { t } = useT();

  const onClick = useCallback(() => {
    if (!_.isNil(stream)) {
      vendors.track.log((events) => {
        return events.openModalFrom({
          nameKey: "simulator",
          placeKey: "timeline",
        });
      });
      setOpenSimulator(true, { stream });
    }
  }, [setOpenSimulator, stream]);

  const value = useMemo(() => {
    if (isDuration) {
      const condition =
        new BigNumber(stream?.timeSinceCliff || 0).isNegative() &&
        !stream?.isCanceled;

      return `${preview.timeSinceEnd}${
        condition ? ` (${preview.timeSinceCliffWorded})` : ""
      }`;
    }

    if (stream?.isCanceled) {
      return `${t("words.on")} ${
        _.toDuration(stream?.canceledTime, "date-full")[0]
      }`;
    }

    return `${t("words.on")} ${_.toDuration(stream?.endTime, "date-full")[0]}`;
  }, [isDuration, preview, stream, t]);

  return (
    <Wrapper isTopHidden>
      <Content>
        <Details.Timeline
          status={stream?.status}
          value={value}
          label={`${t("words.the")} ${preview?.timeSinceEndPrefix}`}
        />
        <Actions>
          <Button
            accent={"iconic"}
            appearance={"outline"}
            onClick={() => setIsDuration((flag) => !flag)}
            right={ArrowsRightLeftIcon}
            tooltip={{
              value: t("structs.toggleFormat"),
            }}
          />
          <Button
            accent={"iconic"}
            appearance={"outline"}
            onClick={onClick}
            right={ArrowTrendingUpIcon}
            tooltip={{
              value: t("words.simulation"),
            }}
          />
        </Actions>
      </Content>
    </Wrapper>
  );
}

function Loading() {
  return (
    <Wrapper isTopHidden>
      <Content data-loading={true}>
        <Details.Loading />
      </Content>
    </Wrapper>
  );
}
export { Loading };
export default Timeline;
