import { useMemo } from "react";
import styled from "styled-components";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { Button, Icon } from "@sablier/v2-components/molecules";
import {
  useChainExplorer,
  useResolvedENS,
  useShortAddressOrName,
} from "@sablier/v2-hooks";
import { _ } from "@sablier/v2-mixins";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    width: 100%;
    & > *:not(:nth-child(2)) {
      flex: 1;
    }
  }
`;

const Middle = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    justify-content: center;
    width: 40px;
    color: ${(props) => props.theme.colors.dark700};
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.betweenLGAndXL} {
    gap: 0px;
    > *,
    > *:not(:nth-child(2)) {
      padding: 2px;
      *[data-component="side"] {
        display: none;
      }
    }

    ${Middle} {
      width: 24px;
    }
  }

  ${(props) => props.theme.medias.maxSM} {
    ${(props) => props.theme.styles.column}
    & {
      gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      > *,
      > *:not(:nth-child(2)) {
        flex: auto;
        width: 100%;
      }

      ${Middle} {
        display: none;
      }
    }
  }
`;

interface PartyProps {
  address?: string;
  chainId?: number;
  prefix?: string;
}

function Party({ address, chainId, prefix }: PartyProps) {
  const isReady = useMemo(
    () => !_.isNilOrEmptyString(chainId) && _.isEthereumAddress(address),
    [address, chainId],
  );

  const { data: ens } = useResolvedENS({ address, chainId, enabled: isReady });
  const short = useShortAddressOrName(ens || address, 6);

  const label = useMemo(() => {
    if (!_.isNilOrEmptyString(prefix)) {
      if (prefix.length > 9) {
        return prefix;
      }
      return `${prefix}: ${short}`;
    }
    return short;
  }, [prefix, short]);

  const explorer = useChainExplorer({
    chainId,
    type: "address",
    hash: address,
  });

  if (!isReady) {
    return <Button accent={"dark300"} appearance={"solid"} isPreview />;
  }

  return (
    <Button
      accent={"dark300"}
      appearance={"solid"}
      left={address}
      leftPurpose={"identicon"}
      onClick={() => {}}
      title={label}
      purpose={"external"}
      to={explorer}
    />
  );
}

interface Props {
  from?: PartyProps;
  to?: PartyProps;
}

function Parties({ from = {}, to = {} }: Props) {
  return (
    <Wrapper>
      <Party {...from} />
      <Middle>
        <Icon value={ChevronDoubleRightIcon} />
      </Middle>
      <Party {...to} />
    </Wrapper>
  );
}

export default Parties;
