import { Fragment, memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import {
  ArrowDownTrayIcon,
  MapIcon,
  NoSymbolIcon,
} from "@heroicons/react/24/outline";
import { Button, Legend, Timeline } from "@sablier/v2-components/molecules";
import { StreamCircle } from "@sablier/v2-components/organisms";
import { StreamCategory } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import {
  useCoverStreamWithdraw,
  useModalStreamDetails,
  useModalStreamHistory,
  useModalStreamLegend,
  useModalStreamSimulator,
  usePollingAwareness,
  useStreamCircleSize,
  useStreamCurrent,
  useToken,
} from "~/client/hooks";

const WrapperPartial = styled.aside`
  ${(props) => props.theme.styles.column}
  & {
    align-items: center;
    width: 100%;
    padding-right: calc(${(props) => props.theme.sizes.edge} * 2);
  }
`;

const Content = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    align-items: center;
    width: 100%;
    padding-top: 84px;
    opacity: 1;
    transition: opacity 1s ease-out;

    & > *:nth-child(1) {
      margin-bottom: 32px;
    }
    & > *:nth-child(2) {
      margin-bottom: 48px;
    }
    & > *:nth-child(3) {
      margin-bottom: 32px;
    }

    &[data-loading="true"] {
      opacity: 500ms;
      transition: opacity 1s ease-out;
    }
  }
`;

const Main = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    position: relative;
    align-items: center;
    width: 100%;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
  width: 100%;
  max-width: ${(props) => _.toSuffix(props.theme.sizes.streamCircle, "px")};
  & > * {
    grid-column: span 1;
    height: 58px;
  }
  &:not([data-placeholder="true"]) {
    ${(props) => props.theme.animations.fadeIn}
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxXL} {
    padding-right: calc(${(props) => props.theme.sizes.edge} * 1);
    ${Content} {
      padding-top: calc(${(props) => props.theme.sizes.edge} * 2);
      & > *:nth-child(1) {
        margin-bottom: calc(${(props) => props.theme.sizes.edge} * 2);
      }
      & > *:nth-child(2) {
        margin-bottom: calc(${(props) => props.theme.sizes.edge} * 2);
      }
    }
  }

  ${(props) => props.theme.medias.maxLG} {
    padding-right: 0;
  }

  ${(props) => props.theme.medias.maxSM} {
    ${Content} {
      padding-top: calc(${(props) => props.theme.sizes.edge} * 1 / 2);

      & > *:nth-child(1) {
        margin-bottom: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      }
      & > *:nth-child(2) {
        margin-bottom: calc(${(props) => props.theme.sizes.edge} * 1);
      }
      & > *:nth-child(3) {
        margin-bottom: calc(${(props) => props.theme.sizes.edge} * 1);
      }
    }
    ${Row} {
      gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      padding-bottom: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    }
    ${Main} {
      padding-top: calc(${(props) => props.theme.sizes.edge} * 1);
      padding-bottom: calc(${(props) => props.theme.sizes.edge} * 0);
    }
  }

  ${(props) => props.theme.medias.maxXS} {
    ${Row} {
      grid-template-columns: 1fr 1fr;
    }
  }
`;

function organize(isDepleted: boolean | undefined, isRecipient: boolean) {
  if (isRecipient && !isDepleted) {
    return ["withdraw", "details"];
  }

  return ["canceled", "details"];
}

const Top = memo(function Top() {
  const { stream, isLinear } = useStreamCurrent();
  const token = useToken({ token: stream?.token });
  const { sizes } = useStreamCircleSize();

  return (
    <Main>
      <StreamCircle
        size={sizes.width}
        streamedAmount={stream?.streamedAmount.humanized.toString()}
        streamedAmountProgress={stream?.streamedAmountPercentage.toNumber()}
        streamedDurationProgress={
          isLinear ? undefined : stream?.streamedDurationPercentage.toNumber()
        }
        status={stream?.status}
        token={token}
        totalAmount={stream?.depositAmount.humanized.toString()}
        withdrawnAmountProgress={stream?.withdrawnAmountPercentage.toNumber()}
      />
    </Main>
  );
});

function Display() {
  usePollingAwareness({ condition: true, page: "profile" });
  const { isLoading, isRecipient, stream, isLinear } = useStreamCurrent();
  const { doOpen: doOpenDetails } = useModalStreamDetails();
  const { doOpen: doOpenHistory } = useModalStreamHistory();
  const { setOpen: setOpenSimulator } = useModalStreamSimulator();
  const { doOpen: doOpenWithdraw } = useCoverStreamWithdraw();
  const { doOpen: doOpenLegend } = useModalStreamLegend();
  const { t } = useT();

  const actions = useMemo(
    () => organize(stream?.isDepleted, isRecipient),
    [stream, isRecipient],
  );

  const timeline = useMemo(() => {
    if (stream && stream.category === StreamCategory.LOCKUP_DYNAMIC) {
      return stream.findTimeline();
    }
    return undefined;
  }, [stream]);

  const onWithdraw = useCallback(() => {
    vendors.track.log((events) => {
      return events.openCover({
        nameKey: "withdraw",
        placeKey: "shortcut",
      });
    });
    doOpenWithdraw();
  }, [doOpenWithdraw]);

  const onDynamic = useCallback(() => {
    vendors.track.log((events) => {
      return events.openModalFrom({
        nameKey: "simulator",
        placeKey: "shortcut",
      });
    });
    setOpenSimulator(true, { stream });
  }, [setOpenSimulator, stream]);

  const openDetails = useCallback(() => {
    vendors.track.log((events) => {
      return events.openStreamDetails("shortcut");
    });
    doOpenDetails();
  }, [doOpenDetails]);

  const openHistory = useCallback(() => {
    vendors.track.log((events) => {
      return events.openModalFrom({
        nameKey: "history",
        placeKey: "shortcut",
      });
    });
    doOpenHistory();
  }, [doOpenHistory]);

  return (
    <Wrapper>
      <Content data-loading={isLoading}>
        <Top />
        <Legend isCanceled isLinear={isLinear} onClick={doOpenLegend} />
        {timeline && <Timeline onDynamic={onDynamic} value={timeline} />}

        <Row>
          {actions.map((action) => (
            <Fragment key={action}>
              {action === "canceled" && (
                <Button
                  accent={"red"}
                  appearance={"solid"}
                  right={NoSymbolIcon}
                  onClick={openHistory}
                  title={t("words.canceled")}
                />
              )}
              {action === "withdraw" && (
                <Button
                  accent={"red"}
                  appearance={"solid"}
                  onClick={onWithdraw}
                  right={ArrowDownTrayIcon}
                  title={_.capitalize(t("words.withdraw"))}
                />
              )}
              {action === "details" && (
                <Button
                  accent={"dark300"}
                  appearance={"solid"}
                  onClick={openDetails}
                  right={MapIcon}
                  title={t("words.details")}
                />
              )}
            </Fragment>
          ))}
        </Row>
      </Content>
    </Wrapper>
  );
}

export default Display;
