import styled from "styled-components";
import { tags } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { useIsSanctionedStream, usePolling } from "~/client/hooks";
import Display from "../Display";
import Panel from "../Panel";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.contained}
  & {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: flex-start;
    width: 100%;
  }
`;

const Left = styled.div`
  ${(props) => props.theme.styles.column}
  ${(props) => props.theme.animations.slideUpFadeIn}
  & {
    flex: 1;
    height: 100%;
    min-height: calc(100vh - ${(props) => props.theme.sizes.toolbar});
    border-right: 2px solid ${(props) => props.theme.colors.border};
  }
`;

const Right = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    --panel: calc(${(props) => props.theme.sizes.panel});
    align-items: flex-start;
    height: 100%;
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxXL} {
    ${Right} {
      --panel: ${(props) => props.theme.sizes.panelMini};
    }
  }
  ${(props) => props.theme.medias.maxLG} {
    grid-template-columns: 1fr;
    width: 100%;
    max-width: 100vw;
    & > * {
      column-span: span 1;
    }

    ${Left} {
      width: 100%;
      min-height: auto;
      border: none;
    }
    ${Right} {
      width: 100%;
      height: auto;
    }
  }
`;

function Body() {
  usePolling({ owned: false, preview: true, page: "profile" });
  const { isSanctioned } = useIsSanctionedStream();

  if (isSanctioned) {
    return false;
  }

  return (
    <Wrapper>
      <Left data-cy={tags.streamDisplay} data-availability={tags.streamDisplay}>
        <Display />
      </Left>
      <Right>
        <Panel />
      </Right>
    </Wrapper>
  );
}

export default Body;
